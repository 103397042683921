import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	// Auth Pages
	{
		path: '/login',
		component: () => import('@/views/auth/Login.vue')
	},
	{
		path: '/register',
		component: () => import('@/views/auth/Register.vue')
	},


	{
		path: '/',
		component: () => import('@/views/Home.vue')
	},
	{
		path: '/profile',
		component: () => import('@/views/Profile.vue')
	},
	{
		path: '/product/:id',
		component: () => import('@/views/Product.vue')
	},
	{
		path: '/cart',
		component: () => import('@/views/Cart.vue')
	},
	{
		path: '/chat',
		component: () => import('@/views/ShowChat.vue')
	},
	// {
	// 	path: '/chat/:id',
	// 	component: () => import('@/views/ShowChat.vue')
	// },
	{
		path: '/order',
		component: () => import('@/views/Order.vue')
	},
	{
		path: '/order/:id',
		component: () => import('@/views/ViewOrder.vue')
	},
	{
		path: '/search',
		component: () => import('@/views/Search.vue')
	},
	{
		path: '/view-image/:id',
		component: () => import('@/views/ViewImage.vue')
	},


	// About Pages
	{
		path: '/kebijakanprivasi',
		component: () => import('@/views/about/KebijakanPribadi.vue')
	},
	{
		path: '/pengumpulan-informasi-pribadi',
		component: () => import('@/views/about/PengumpulanInformasiPribadi.vue')
	},
	{
		path: '/syarat-dan-ketentuan',
		component: () => import('@/views/about/SyaratdanKetentuan.vue')
	},
	{
		path: '/tentang-rinyuakng-squad',
		component: () => import('@/views/about/TentangRinyuakngSquad.vue')
	},
	
	// Mitra pages
	{
		path: '/mitra/dashboard',
		component: () => import('@/views/mitra/Home.vue')
	},
	{
		path: '/mitra/products',
		component: () => import('@/views/mitra/Products.vue')
	},
	{
		path: '/mitra/products/:id',
		component: () => import('@/views/mitra/EditProduct.vue')
	},
	{
		path: '/mitra/add-product',
		component: () => import('@/views/mitra/AddProduct.vue')
	},
	{
		path: '/mitra/orders',
		component: () => import('@/views/mitra/Orders.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
